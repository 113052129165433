@import "./_font.scss";
@import "./_reset.scss";
@import "./_variable.scss";

.app {
  height: 100vh;
  color: var(--primary-color);
  background-color: var(--main-color);

  .sections {
    height: 100vh;
    overflow: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    scrollbar-width: none; // firefox
    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      width: calc(100vw - var(--sidebar-width));
      position: relative;
      left: var(--sidebar-width);
      height: 100vh;
    }
    > *:not(:first-child){
        align-items: center;
    }
  }
}

.tags{
    font-size: 1.5rem;
    font-family: La;
    color: #555;
}

.intialLoad{
    opacity: 0;
    animation-name: showBack;
    animation-duration: 1.5s;
    animation-delay: 3.9s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}

.intialLoadLogo{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    animation-name: logo;
    animation-duration: 2s;
    animation-delay: 2.4s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
}


@keyframes showBack {
    0%{
        opacity: 0;
    }
    
    100%{
        opacity: 1;
    }
}

@keyframes logo {
    0%{
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    100%{
        opacity: 0;
        transform: translate(-894px, -424px) scale(.16)
    }
}

@keyframes logo2 {
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform:scale(.16)
    }
}

.fillSVG path{
    fill: #FF0000;
}

@media screen and (max-width:1300px ){
    .app .sections{
        > * {
            left: 3rem;
            width: calc(100vw - 3rem);
            height: auto;
        }
        > *:first-child{
            height: inherit;
        }
    }
    .intialLoadLogo{
        animation-name: logo2;
    }
  }