.intro {
  background-color: var(--main-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: var(--intro-padding);
  gap: 6rem;
}

.introDesc {
  display: inline-block;
  font-size: 9rem;
  font-weight: bold;
  line-height: 1;
  font-family: var(--head-font);
}

.introTags {
  top: var(--intro-padding);
  position: absolute;

  > *:first-child {
    margin-left: -2rem;
  }
  > *:last-child {
    margin-left: -1rem;
  }
}

.hTagStart {
  top: var(--tag-spacing);
  margin-bottom: 3rem;;
}

.hTagEnd {
  position: absolute;
  bottom: var(--tag-spacing);
}

.introDesc:hover {
  cursor: pointer;
  color: var(--hover-color);
  animation: rubberBand;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.headDesc {
  font-size: 2rem;
  color: #8d8d8d;
  word-spacing: 0.8rem;
}

.pTagEnd {
  bottom: var(--tag-spacing);
  margin-top: 3rem;
}

.bottomDesc {
  position: relative;
}

.scrollDown {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;

  span {
    writing-mode: vertical-rl;
    animation-name: bounceWord;
    animation-duration: 0.5s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    cursor: none;
  }
}

@keyframes rubberBand {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceWord {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, -2rem, 0);
  }
}

.introMarLeft{
  margin-left: 2rem;
}

@media screen and (max-width:1300px ){
  .intro{
    padding-left:0rem !important;
    padding-right:3rem !important;
  }
  .introDesc{
    font-size: 9rem;
  }
  .introTags{
    top: 0;
    position: relative;
  }
}

@media screen and (max-width:800px ){
  .introDesc{
    font-size: 7rem;
  }
}

@media screen and (max-width:650px ){
  .introDesc{
    font-size: 5rem;
  }

  .introMarLeft{
    margin-left: 1rem;
  }
}

@media screen and (max-width:500px ){
  .introDesc{
    font-size: 4rem;
  }
  .headDesc{
    font-size: 1.5rem;
  }
}
@media screen and (max-width:400px ){
  .introDesc{
    font-size: 2.5rem;
  }
  .headDesc{
    font-size: 1.5rem;
  }
}