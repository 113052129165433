.sidebar {
  width: var(--sidebar-width);
  height: 100vh;
  background-color: var(--sidebar-color);
  position: fixed;
  top: 0;
  z-index: 2;
  border-right:1px solid var(--border-color);
}

.logo {
  background-color: #0c0c0c;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;
}

.imageLogo {
  width: 5rem;
  height: 5rem;
}

.logoName {
  margin-top: 0.3rem;
  font-size: 1.5rem;
}

.logoDesc {
  margin-top: 0.5rem;
}

.actions {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.navigation {
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 1px solid var(--border-color);
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--primary-color);
    text-decoration: none;
  }

  > *:last-child {
    border-bottom: 1px solid var(--border-color);
  }

  > *:hover {
    color: var(--hover-color);
  }
}

.socialIcons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 1.5rem;
  cursor: pointer;

  > * {
    color: var(--primary-color);
    transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  }

  > *:hover {
    color: var(--hover-color);
    transform: scale(1.5);
  }
}

@media screen and (min-width:1301px ){
  .hamIcon{
    display: none;
  }
}

@media screen and (max-width:1300px ){
  .sidebar{
    display: none;
    width: 100%;
  }
  .hamIcon{
    font-size: 3rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 20
  }
}
