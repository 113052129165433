.contact {
  background-color: var(--main-color);
  padding: var(--intro-padding);
  display: flex;
  gap: 5rem;
}

.connectHead {
  font-size: 6rem;
  color: var(--hover-color);
  font-family: var(--head-font);
}

.connectIcons {
  font-size: 2rem;
  display: flex;
  color: var(--primary-color);
  gap: 1rem;
  align-items: center;
  margin-top: 9rem;
  a {
    color: var(--primary-color);
    text-decoration: none;
  }
}

.connectMain {
  width: 65%;
}

.nameIP,
.mailIP,
.subIP,
.msgIP {
  width: 100%;
  margin-bottom: 1.5rem;
}

.contactTag {
  margin-top: 3rem;
}

.contactInput,
input:-internal-autofill-selected {
  background-color: #2b2b2b;
  height: 50px;
  border: none;
  border-radius: 4px;
  color: var(--primary-color);
  padding: 0 20px;
  font-size: 1.3333rem;
}

.msgIP {
  min-height: 150px;
  padding: 20px;
}
.contactInput:focus {
  outline: 0;
}

.contactInput:focus::placeholder {
  color: transparent;
}

.contactInput:focus + .ipLabel::after {
  opacity: 1;
}

.mailForm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sendMsg {
  background-color: var(--main-color);
  color: var(--hover-color);
  outline: 0;
  border: none;
  border: 1px solid var(--hover-color);
  height: 50px;
  width: 100%;
  max-width: 200px;
  font-size: 1.167rem;
  cursor: pointer;
  border-radius: 4px;
}

.sendMsg:hover {
  background: var(--hover-color);
  transition: 0.3s ease-out all;
  color: var(--primary-color);
}

.ipLabel::before,
.ipLabel::after {
  content: "";
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 1px;
}

.ipLabel::after {
  margin-top: 2px;
  opacity: 0;
  border-bottom: 2px solid var(--hover-color);
  transition: opacity 0.3s;
  top: 50px;
}

.ipLabelMsg::after,
ipLabelMsg::before {
  top: 150px;
}

.loader {
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  right: 10px;
  top: 115px;
  animation: load 2s linear forwards infinite;
}

@keyframes load {
  0% {
    transform: rotate(0deg);
    box-shadow: 1px 2.5px 1px #FF0000;
  }
  50% {
    transform: rotate(180deg);
    box-shadow: 1px 2.5px 1px #f54291;
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 1px 2.5px 1px #4cd3c2;
  }
}

.msgToater {
  position: absolute;
  bottom: 14rem;
  right: 40rem;
  font-size: 16px;
  color: var(--primary-color);
  background-color: var(--hover-color);
  padding: 1rem;
  border-radius: 4px;
  animation: toast 0.5s linear forwards;
}

@keyframes toast {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-60px);
  }

  100% {
    transform: translateY(-120px);
  }
}

.exitTags {
  position: absolute;
  bottom: 0;
  left: var(--intro-padding);

  > *:last-child {
    margin-left: -2rem;
  }
  > *:first-child {
    margin-left: -1rem;
  }
}

@media screen and (max-width: 1300px) {
  .contact {
    padding-left: 0rem !important;
    padding-right: 3rem !important;
  }
  .exitTags {
    left: 0;
  }
}

@media screen and (max-width:850px ){

  .connectMain {
    width: 100%;
  }
  .contact{
      flex-direction: column;
  }
}
@media screen and (max-width: 650px) {
  .connectIcons{
    margin-top: 7rem;
  }
}
