.skills{
    background-color: var(--main-color);
    padding: var(--intro-padding);
    display: flex;
    gap: 5rem;
}

.tagCloudOuter {
	bottom: 0;
	position: absolute;
	right: 0;
	top: 0;
    width: 50%;
}

.tagCloudInner {
	display: flex;
	flex-direction: column;
	height: 100%;
	max-width: 1000px;
	width: 100%;
}

.skillSet, .skillCanvas{
    width: 50%;
}


.tagCloud {
	flex: 1;
}

.tagCloud > div {
	transition: 3s;
    transition-delay: 1s;
    font-size: 3rem !important;
    padding: 1rem;
    color: #939393;
    cursor: none;
}

.skillSec{
    font-size: 2rem;

    & >p{
        padding: 1rem;
    }
}

@media screen and (max-width:1300px ){
    .skills{
      padding-left:0rem !important;
      padding-right:3rem !important;
    }
  }
  @media screen and (max-width:1000px ){
    .skillSec{
      font-size: 1.5rem;
    }
    .connectHead{
        font-size: 4.5rem;
    }
  }
  @media screen and (max-width:850px ){
      .connectHead{
          font-size: 4rem;
      }

      .skillSet, .skillCanvas{
        width: auto;
    }

    .skills{
        flex-direction: column;
        position: relative;
    }
    .skillCanvas{
        height: 185px;
    }
    .tagCloud > div {
        font-size: 2.5rem !important;
    }

    .tagCloudOuter{
        width: 100%;
        height: 40%;
        z-index: 10;
        top: 50rem;
        left: 0;
    }
  }
  
  
  
  @media screen and (max-width:550px ){
    .tagCloud > div {
        font-size: 1.5rem !important;
        z-index: 10;
    }

    .tagCloudOuter{
        top: 58rem;
        padding: 0rem;
        left: 0;
    }
  }
  @media screen and (max-width:475px ){
    .tagCloudOuter{
        top: 72rem;
        padding: 0rem;
        left: 0;
    }

    .skillCanvas{
        height: 215px;
    }
    .connectHead{
        font-size: 3rem;
    }
  }

  @media screen and (max-width:410px ){
    .tagCloudOuter{
        top: 76rem;
    }

    .skillCanvas{
        height: 215px;
    }
  }

  @media screen and (max-width:350px ){
    .tagCloudOuter{
        top: 82rem;
    }

    .skillCanvas{
        height: 300px;
    }
  }