.projects{
    background-color: var(--main-color);
    padding: var(--intro-padding);
}

.projectRows{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10rem;
}

.project1, .project2, .project3, .project4{
    border: 1px solid var(--hover-color);
    border-radius: 4px ;
    font-size: 2rem;
    padding: 2rem;
    margin: 10rem 0 0 0;
    color: var(--primary-color);
    cursor: pointer;
    &>.projectSummary{
        font-size: 1.5rem;
    }
}

.project1, .project3{
    margin: 5rem 0 0 0;
}


.upcoming{
    font-size: 1rem;
    float: right;
    margin-top: 1rem;
}

a{
    text-decoration: none;
}

.visit{
    font-size: 1.5rem;
    color: var(--hover-color);
    margin-top: 1rem;
}

@media screen and (max-width:1300px ){
    .projects{
      padding-left:0rem !important;
      padding-right:3rem !important;
    }
  }

  @media screen and (max-width:850px ){
    .projectRows{
        flex-direction: column;
    }
    .project1, .project3{
        margin: 0;
        width: calc(100vw - 6rem);
    }
  }